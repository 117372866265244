/**
 * Something listening to something else
 */
export interface Listener {
  /**
   * Stop listening
   */
  unregister: () => void;
}

/**
 * Create a new simple listener
 * @param onUnregister
 */
export function newListener(onUnregister: () => void): Listener {
  let isRegistered = true;
  return {
    unregister() {
      if (isRegistered) {
        isRegistered = false;
        onUnregister();
      }
    },
  };
}
