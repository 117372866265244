import { Installer } from "./Installer";
import { asIframeContainerId } from "../dom";
import { Pupper } from "../bark/Pupper";
import { InstallerOptions } from "./InstallerOptions";
import { generateRandomId } from "../id";
import { LIBRARY_VERSION } from "../lib";

const extraUtmParams = function (params: URLSearchParams) {
  // Pull out search params
  const rawParams = window.location.href.split(/[?&]/);

  // First parameter is the page URL, which is unimportant
  const searchParams = rawParams.slice(1);

  for (let i = 0; i < searchParams.length; ++i) {
    // Split into key=value
    const pairs = searchParams[i].split("=");
    const key = pairs[0];
    const value = pairs[1];

    // Skip bad
    if (key === undefined || value === undefined) {
      continue;
    }

    // Capture UTM keys
    if (key.startsWith("utm_")) {
      params.set(key, value.trim());
    }
  }
};

/**
 * An installer backed by an iframe on the document
 */
export abstract class IframeInstaller<T> implements Installer<T> {
  /**
   * The real install
   * @param id
   * @param url
   * @param params
   * @param options,
   * @param installOptions,
   */
  protected async installIframe(
    id: string,
    url: string,
    params: URLSearchParams,
    options: InstallerOptions,
    installOptions: {
      show: boolean;
    }
  ): Promise<void> {
    const { show } = installOptions;
    const logger = new Pupper("install/IframeInstaller", options.debug);

    // Unique hash
    if (!params.has("hash")) {
      params.set("hash", generateRandomId());
    }

    // Identifying info
    if (!params.has("url")) {
      params.set("url", window.location.href);
    }

    // Identifying info
    if (!params.has("version")) {
      params.set("version", LIBRARY_VERSION);
    }

    extraUtmParams(params);

    const containerId = asIframeContainerId(id);
    const existing = document.getElementById(containerId);
    if (existing) {
      logger.d("Iframe already exists", containerId);
      return Promise.resolve();
    }

    const container = document.createElement("div");
    container.id = containerId;
    container.style.position = "fixed";
    container.style.border = "0";
    container.style.margin = "0";
    container.style.padding = "0";
    container.style.outline = "none";
    container.style.overflow = "hidden";
    container.style.height = "100%";
    container.style.width = "100%";

    container.style.visibility = show ? "visible" : "hidden";
    container.style.left = show ? "0" : "-9999px";
    container.style.top = show ? "0" : "-9999px";
    container.style.bottom = show ? "0" : "";
    container.style.right = show ? "0" : "";
    container.style.zIndex = `${
      show ? Number.MAX_SAFE_INTEGER - 1 : Number.MAX_SAFE_INTEGER + 1
    }`;

    // Fix for Safari z-index
    container.style.transform = "translateZ(1px)";

    const iframe = document.createElement("iframe");
    iframe.id = id;
    iframe.style.border = "0";
    iframe.style.margin = "0";
    iframe.style.padding = "0";
    iframe.style.outline = "none";
    iframe.style.overflow = "hidden";
    iframe.style.height = "100%";
    iframe.style.width = "100%";

    iframe.src = `${url}?${params.toString()}`;

    logger.d("Load iframe with id and target: ", {
      id,
      target: iframe.src,
      params: params.toString(),
    });

    return new Promise((resolve, reject) => {
      iframe.addEventListener("load", () => {
        logger.d("Iframe has loaded!", id);
        resolve();
      });

      iframe.addEventListener("error", (error) => {
        logger.w("Error loading iframe", id, error);
        reject(error);
      });

      container.appendChild(iframe);
      document.body.insertBefore(container, document.body.firstChild);
    });
  }

  /**
   * Override
   *
   * @param id
   * @param url
   * @param options
   */
  abstract install(id: string, url: string, options: T): Promise<void>;
}
