import { Installer } from "../install/Installer";
import { DobermanInstallOptions } from "./DobermanInstallOptions";
import { LIBRARY_NAME, LIBRARY_VERSION } from "../lib";
import { BeagleServicesIds } from "../id";
import { Pupper } from "../bark/Pupper";
import { Environment } from "../Environment";
import { BeagleAppUrls, BeagleDevUrls } from "../url";

/**
 * Get the iframe URL source
 */
const getIframeUrl = function (environment: Environment): string {
  switch (environment) {
    case Environment.DEV:
      return BeagleDevUrls.DOBERMAN;
    case Environment.PROD:
      return BeagleAppUrls.DOBERMAN;
    case Environment.LOCAL:
      return "http://localhost:3000";
  }
};

/**
 * Get the iframe ID
 *
 * @param id
 * @param options
 */
const getDobermanIframeId = function (
  id: string,
  options: DobermanInstallOptions
): string {
  const core: string[] = [LIBRARY_NAME, LIBRARY_VERSION, id];
  const runtime: string[] = Object.keys(options).map((k: string) => {
    const key = k as keyof DobermanInstallOptions;
    return `${options[key]}`;
  });
  return [...core, ...runtime].join("-");
};

export class Doberman {
  private readonly logger: Pupper;
  private readonly installer: Installer<DobermanInstallOptions>;

  private iframeId: string;

  private installedOptions: DobermanInstallOptions | undefined;

  constructor(installer: Installer<DobermanInstallOptions>) {
    this.logger = new Pupper("doberman/Doberman");
    this.installer = installer;

    this.installedOptions = undefined;
    this.iframeId = "";
  }

  private setIframeId(id: string) {
    this.iframeId = id;
  }

  public install(options: DobermanInstallOptions): void {
    if (this.iframeId) {
      this.logger.w("Poodle already installed", {
        id: this.iframeId,
        lib: LIBRARY_NAME,
        version: LIBRARY_VERSION,
        options: this.installedOptions,
      });
      return;
    }

    this.installedOptions = options;
    const { debug } = options;
    this.logger.setDebug(debug);

    // Create the expected iframe ID
    const labId = getDobermanIframeId(
      BeagleServicesIds.DOBERMAN,
      options
    );
    this.logger.d("Attempt Doberman install...", {
      id: labId,
      lib: LIBRARY_NAME,
      version: LIBRARY_VERSION,
      options,
    });

    const { environment } = options;
    this.installer
      .install(labId, getIframeUrl(environment), options)
      .then(() => {
        // Save for later
        this.setIframeId(labId);
      })
      .then(() => {
        this.logger.d("Doberman has been installed!", {
          id: this.iframeId,
          lib: LIBRARY_NAME,
          version: LIBRARY_VERSION,
          options,
        });

        if (options.onInstalled) {
          options.onInstalled();
        }
      })
      .catch(() => {
        this.logger.w("Doberman was already installed", {
          id: this.iframeId,
          lib: LIBRARY_NAME,
          version: LIBRARY_VERSION,
          options: this.installedOptions,
        });
      });
  }
}
