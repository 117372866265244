import { Messenger } from "./Messenger";
import { MessengerOptions } from "./MessengerOptions";

/**
 * A messenger which fires to a DOM id element
 */
export abstract class DOMMessenger<T extends MessengerOptions> implements Messenger<T> {
  /**
   * Iframe ID
   */
  protected id: string;

  protected constructor() {
    this.id = "";
  }

  /**
   * Set the id for the iframe
   * @param id
   */
  setId(id: string) {
    this.id = id;
  }

  abstract postMessage(
    message: any,
    origin: string,
    options: MessengerOptions
  ): void;
}
