import { PoodleInstallOptions } from "../../poodle/PoodleInstallOptions";
import { LIBRARY_NAME } from "../../lib";

export function asMessageId(id: string, options: PoodleInstallOptions): string {
  return [
    LIBRARY_NAME,
    id,
    options.environment,
    options.partner,
    options.region,
    options.debug,
  ].join("-");
}

/**
 * The internal message ID
 */
export const POODLE_INTERNAL_MESSAGE_ID = "#INTERNAL#BS-POODLE-V1";

/**
 * The message ID
 */
export const POODLE_MESSAGE_ID = "BS-POODLE-V1";
