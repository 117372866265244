import { IframeInstaller } from "../install/IframeInstaller";
import { Pupper } from "../bark/Pupper";
import { DobermanInstallOptions } from "./DobermanInstallOptions";

export class DobermanInstaller extends IframeInstaller<DobermanInstallOptions> {
  /**
   * Override
   * @param id
   * @param url
   * @param options
   */
  async install(
    id: string,
    url: string,
    options: DobermanInstallOptions
  ): Promise<void> {
    const logger = new Pupper(
      "doberman/DobermanInstaller",
      options.debug
    );

    const params = new URLSearchParams();

    // Needed to open poodle in proactive mode
    params.set("proactive", "true");

    for (const k of Object.keys(options)) {
      const key = k as keyof DobermanInstallOptions;
      params.set(key, `${options[key]}`);
    }

    logger.d("Install Doberman into iframe: ", {
      params: params.toString(),
    });

    return this.installIframe(id, url, params, options, { show: true });
  }
}
