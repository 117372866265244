import { Poodle as PoodleWalker } from "./poodle/Poodle";
import { Doberman as DobermanWalker } from "./doberman/Doberman";
import { IframeMessenger } from "./message/IframeMessenger";
import { ParentMessenger } from "./message/ParentMessenger";
import { PoodleInstaller } from "./poodle/PoodleInstaller";
import { Environment } from "./Environment";
import { Listener as _Listener, newListener } from "./Listener";
import { InternalPoodleGuts } from "./internal/poodle/InternalPoodleGuts";
import { PoodleInstallOptions as _PoodleInstallOptions } from "./poodle/PoodleInstallOptions";
import { PoodleLaunchOptions as _PoodleLaunchOptions } from "./poodle/PoodleLaunchOptions";
import { PoodleGutsOptions as _PoodleGutsOptions } from "./poodle/PoodleGutsOptions";
import {
  PoodlePartnerInfo as _PoodlePartnerInfo,
  PoodlePartnerInfoAddress as _PoodlePartnerInfoAddress,
  PoodlePartnerInfoCustomer as _PoodlePartnerInfoCustomer
} from "./poodle/PoodlePartnerInfo";
import { DobermanInstaller } from "./doberman/DobermanInstaller";
import { DobermanInstallOptions as _DobermanInstallOptions } from "./doberman/DobermanInstallOptions";

/**
 * Direct exports
 * =============================================
 */
export { Environment, newListener };
/**
 * =============================================
 */

/**
 * Export as a direct Type alias
 * =============================================
 */
// noinspection JSUnusedGlobalSymbols
export type Listener = _Listener;

/**
 * =============================================
 */

/**
 * Poodle
 * =============================================
 */
// noinspection JSUnusedGlobalSymbols
export type PoodleInstallOptions = _PoodleInstallOptions;
// noinspection JSUnusedGlobalSymbols
export type PoodleLaunchOptions = _PoodleLaunchOptions;
// noinspection JSUnusedGlobalSymbols
export type PoodleGutsOptions = _PoodleGutsOptions;

// noinspection JSUnusedGlobalSymbols
export type PoodlePartnerInfo = _PoodlePartnerInfo;
// noinspection JSUnusedGlobalSymbols
export type PoodleInfoCustomer = _PoodlePartnerInfoCustomer;
// noinspection JSUnusedGlobalSymbols
export type PoodleInfoAddress = _PoodlePartnerInfoAddress;

export function newPoodle(): PoodleWalker {
  return new PoodleWalker(
    new InternalPoodleGuts(new ParentMessenger()),
    new PoodleInstaller(),
    new IframeMessenger()
  );
}

// noinspection JSUnusedGlobalSymbols
export const Poodle = newPoodle();

/**
 * =============================================
 */

/**
 * Doberman
 * =============================================
 */
// noinspection JSUnusedGlobalSymbols
export type DobermanInstallOptions = _DobermanInstallOptions;

export function newDoberman(): DobermanWalker {
  return new DobermanWalker(new DobermanInstaller());
}

// noinspection JSUnusedGlobalSymbols
export const Doberman = newDoberman();
/**
 * =============================================
 */
