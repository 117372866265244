import { v4 } from "uuid";

/**
 * Beagle Services ids
 */
export enum BeagleServicesIds {
  /**
   * The expected id of the iframe
   */
  POODLE = "BeagleServices-poodle",

  DOBERMAN = "BeagleServices-doberman",
}

/**
 * Generates a random UUID
 */
export function generateRandomId(): string {
  return v4();
}
