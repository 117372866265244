import { IframeInstaller } from "../install/IframeInstaller";
import { Pupper } from "../bark/Pupper";
import { PoodleGutsOptions } from "./PoodleGutsOptions";

export class PoodleInstaller extends IframeInstaller<PoodleGutsOptions> {
  /**
   * Override
   * @param id
   * @param url
   * @param options
   */
  async install(
    id: string,
    url: string,
    options: PoodleGutsOptions
  ): Promise<void> {
    const logger = new Pupper("poodle/PoodleInstaller", options.debug);

    const params = new URLSearchParams();

    const { info, ...rest } = options;

    // Info is special
    if (info) {
      params.set("info", JSON.stringify(info));
    }

    for (const k of Object.keys(rest)) {
      // @ts-ignore
      params.set(k, `${rest[k]}`);
    }

    logger.d("Install Poodle into iframe: ", {
      params: params.toString(),
    });

    return this.installIframe(id, url, params, options, { show: false });
  }
}
