import { Messenger } from "./Messenger";
import { MessengerOptions } from "./MessengerOptions";
import { Pupper } from "../bark/Pupper";

/**
 * A messenger backed by a parent window
 */
export class ParentMessenger implements Messenger<MessengerOptions> {
  /**
   * Logger
   */
  private readonly logger: Pupper;

  constructor() {
    this.logger = new Pupper("message/ParentMessenger");
  }

  /**
   * Post a message
   * @param message
   * @param origin
   * @param options
   */
  postMessage(message: any, origin: string, options: MessengerOptions): void {
    this.logger.setDebug(options.debug);
    const { parent } = window;

    if (!parent) {
      const msg = `Missing parent window: Is this an iframe?`;
      this.logger.e(msg);
      throw new Error(msg);
    }

    this.logger.d("Post message up to parent: ", {
      message,
      origin,
      options,
    });

    parent.postMessage(message, origin);
  }
}
