import { Messenger } from "../../message/Messenger";
import { MessengerOptions } from "../../message/MessengerOptions";
import { PoodleMessages } from "../../poodle/PoodleMessages";
import { Listener, newListener } from "../../Listener";
import { POODLE_MESSAGE_ID } from "./PoodleMessageId";
import { PoodleCommands } from "../../poodle/PoodleCommands";
import { PoodleLaunchOptions } from "../../poodle/PoodleLaunchOptions";
import { PoodleGuts } from "../../poodle/PoodleGuts";

/**
 * Internal poodle command handler
 *
 * Includes private scoped logging methods
 */
export class InternalPoodleGuts extends PoodleGuts {
  constructor(messenger: Messenger<MessengerOptions>) {
    super(messenger);
  }

  /**
   * Override
   */
  public listenForMessages(listener: PoodleMessages): Listener {
    const onMessage = (message: MessageEvent) => {
      const { data } = message;
      const { id, name, options } = data;

      if (id !== this.createMessageId(POODLE_MESSAGE_ID)) {
        return;
      }

      switch (name) {
        case PoodleCommands.OPEN:
          listener.onOpen(
            options ? (options as PoodleLaunchOptions) : undefined
          );
          break;
        default:
          return;
      }
    };

    const logger = this.logger;
    const env = this.environment;
    logger.d("Begin listening for Poodle messages", {
      environment: env,
    });
    window.addEventListener("message", onMessage);
    return newListener(() => {
      logger.d("Stop listening for Poodle messages", {
        environment: env,
      });
      window.removeEventListener("message", onMessage);
    });
  }
}
