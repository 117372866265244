/**
 * Beagle Services URL
 */
export enum BeagleDevUrls {
  // Could become dev-poodle.beagleservices.com but currently that URL does not resolve
  POODLE = "https://dev-poodle.beagleservices.com",
  DOBERMAN = "https://dev-poodle.beagleservices.com",
}

export enum BeagleAppUrls {
  POODLE = "https://app-poodle.beagleservices.com",
  DOBERMAN = "https://app-poodle.beagleservices.com",
}
