import { PoodleCommands } from "./PoodleCommands";
import { Installer } from "../install/Installer";
import { BeagleServicesIds } from "../id";
import {
  getPoodleIframeId,
  InternalPoodleCommands,
} from "../internal/poodle/InternalPoodleCommands";
import { Dog } from "../dog/Dog";
import { asIframeContainerId } from "../dom";
import { PoodleInstallOptions } from "./PoodleInstallOptions";
import { Pupper } from "../bark/Pupper";
import { MessengerOptions } from "../message/MessengerOptions";
import { DOMMessenger } from "../message/DOMMessenger";
import { BeagleAppUrls, BeagleDevUrls } from "../url";
import { LIBRARY_NAME, LIBRARY_VERSION } from "../lib";
import { Environment } from "../Environment";
import { PoodleGutsOptions } from "./PoodleGutsOptions";
import { PoodleLaunchOptions } from "./PoodleLaunchOptions";
import {
  asMessageId,
  POODLE_INTERNAL_MESSAGE_ID,
  POODLE_MESSAGE_ID,
} from "../internal/poodle/PoodleMessageId";
import { PoodleGuts } from "./PoodleGuts";
import { InternalPoodleGuts } from "../internal/poodle/InternalPoodleGuts";

/**
 * Sends messages to BeagleServices Poodle
 */
export class Poodle
  implements Dog<PoodleGuts, PoodleInstallOptions, PoodleLaunchOptions>
{
  /**
   * The messenger implementation
   */
  private readonly installer: Installer<PoodleInstallOptions>;

  /**
   * The messenger implementation
   */
  private readonly childMessenger: DOMMessenger<MessengerOptions>;

  /**
   * Internals
   */
  private readonly internals: PoodleGuts;

  /**
   * Logger
   */
  private readonly logger: Pupper;

  /**
   * Is debug mode
   */
  private isDebug: boolean;

  /**
   * Run environment
   */
  private environment: Environment;

  /**
   * For message ID
   */
  private messageRegion: string;
  private messagePartner: string;

  /**
   * The id of this container
   */
  private id: string;

  constructor(
    internals: InternalPoodleGuts,
    installer: Installer<PoodleInstallOptions>,
    childMessenger: DOMMessenger<MessengerOptions>
  ) {
    this.id = "";
    this.messagePartner = "";
    this.messageRegion = "";

    this.isDebug = false;
    this.environment = Environment.PROD;

    this.internals = internals;
    this.installer = installer;
    this.childMessenger = childMessenger;
    this.logger = new Pupper("poodle/Poodle");
  }

  /**
   * Override
   */
  public guts(): PoodleGuts {
    return this.internals;
  }

  /**
   * Update the iframe container
   */
  private updateContainer(
    show: boolean,
    options: PoodleLaunchOptions | undefined
  ) {
    const id = this.id;
    if (!id) {
      this.logger.w("Cannot update container, missing id");
      return;
    }

    const containerId = asIframeContainerId(id);
    const container = document.getElementById(containerId);
    if (!container) {
      this.logger.w("Cannot find the Poodle iframe container: ", containerId);
      // Cannot find container, do not update
      return;
    }

    this.logger.d("Update container state: ", {
      show,
      options,
    });
    container.style.visibility = show ? "visible" : "hidden";
    container.style.left = show ? "0" : "-9999px";
    container.style.top = show ? "0" : "-9999px";
    container.style.bottom = show ? "0" : "";
    container.style.right = show ? "0" : "";
    container.style.zIndex = `${
      show ? Number.MAX_SAFE_INTEGER - 1 : Number.MIN_SAFE_INTEGER + 1
    }`;
  }

  /**
   * Get the iframe URL source
   */
  private getMessageOrigin(): string {
    switch (this.environment) {
      case Environment.DEV:
        return BeagleDevUrls.POODLE;
      case Environment.PROD:
        return BeagleAppUrls.POODLE;
      case Environment.LOCAL:
        return "http://localhost:3000";
    }
  }

  /**
   * Create the message ID
   */
  private createMessageId(id: string): string {
    return asMessageId(id, {
      environment: this.environment,
      partner: this.messagePartner,
      region: this.messageRegion,
      debug: this.isDebug,
    });
  }

  /**
   * Send a message to the iframe from a beagleservices domain
   *
   * @param command
   * @param options
   */
  private sendMessage(
    command: PoodleCommands,
    options: PoodleLaunchOptions | undefined
  ) {
    const origin = this.getMessageOrigin();
    const data = {
      id: this.createMessageId(POODLE_MESSAGE_ID),
      name: command,
      options: options,
    };

    const messageOptions = {
      debug: this.isDebug,
    };

    this.logger.d("Post message to child iframe: ", {
      environment: this.environment,
      message: data,
      origin,
      messageOptions,
    });

    this.childMessenger.postMessage(data, origin, messageOptions);
  }

  private watchForInternalMessages(onClose?: () => void) {
    this.logger.d("Watching for internal Poodle messages");
    window.addEventListener("message", (message) => {
      const { data } = message;
      const { id, name } = data;

      if (id !== this.createMessageId(POODLE_INTERNAL_MESSAGE_ID)) {
        return;
      }

      // If this is a close command from the child modal, update the container
      this.logger.d("Internal message: ", name);
      if (name === InternalPoodleCommands.CLOSE) {
        this.updateContainer(false, undefined);
        if (onClose) {
          onClose();
        }
      }
    });
  }

  /**
   * Get the iframe URL source
   */
  private getIframeUrl(): string {
    switch (this.environment) {
      case Environment.DEV:
        return BeagleDevUrls.POODLE;
      case Environment.PROD:
        return BeagleAppUrls.POODLE;
      case Environment.LOCAL:
        return "http://localhost:3000";
    }
  }

  private setIframeId(id: string) {
    this.id = id;
  }

  /**
   * Override
   */
  public install(options: PoodleInstallOptions): void {
    if (this.id) {
      this.logger.w("Poodle already installed", {
        id: this.id,
        lib: LIBRARY_NAME,
        version: LIBRARY_VERSION,
        isDebug: this.isDebug,
        environment: this.environment,
        partner: this.messagePartner,
        region: this.messageRegion,
      });
      return;
    }

    // Assign state tracker booleans
    this.isDebug = options.debug !== undefined ? options.debug : false;
    this.environment =
      options.environment !== undefined
        ? options.environment
        : Environment.PROD;
    this.messagePartner = options.partner.toUpperCase();
    this.messageRegion =
      options.region !== undefined ? options.region.trim() : "";

    const installerOptions: PoodleGutsOptions = {
      // Environment
      environment: this.environment,
      debug: this.isDebug,
      partner: this.messagePartner,
      region: this.messageRegion,

      // Extra
      landlord: options.landlord !== undefined ? options.landlord : false,
      productOffering:
        options.productOffering !== undefined
          ? options.productOffering.trim()
          : "",

      // Reschedule
      customer: options.customer !== undefined ? options.customer.trim() : "",
      job: options.job !== undefined ? options.job.trim() : "",
      zipCode: options.zipCode !== undefined ? options.zipCode.trim() : "",
      customerRegion:
        options.customerRegion !== undefined
          ? options.customerRegion.trim()
          : "",
      address: options.address !== undefined ? options.address.trim() : "",

      // Proactive
      proactiveUserId:
        options.proactiveUserId !== undefined
          ? options.proactiveUserId.trim()
          : "",
      proactiveDeviceId:
        options.proactiveDeviceId !== undefined
          ? options.proactiveDeviceId.trim()
          : "",
      proactiveAddressId:
        options.proactiveAddressId !== undefined
          ? options.proactiveAddressId.trim()
          : "",
      proactiveAlertType:
        options.proactiveAlertType !== undefined
          ? options.proactiveAlertType.trim()
          : "",

      // Partner Integrations
      exportData: options.exportData !== undefined ? options.exportData : false,
      info: options.info,
    };

    // Update internals with state tracker booleans
    this.internals.install(installerOptions);
    this.logger.setDebug(this.isDebug);

    // Create the expected iframe ID
    const poodleId = getPoodleIframeId(
      BeagleServicesIds.POODLE,
      this.environment,
      installerOptions
    );
    this.childMessenger.setId(poodleId);

    this.logger.d("Attempt Poodle install...", {
      id: poodleId,
      lib: LIBRARY_NAME,
      version: LIBRARY_VERSION,
      installerOptions,
    });

    this.installer
      .install(poodleId, this.getIframeUrl(), installerOptions)
      .then(() => {
        // Save for later
        this.setIframeId(poodleId);

        // After install set up a listener for messages back from the child
        this.watchForInternalMessages(options.onClose);
      })
      .then(() => {
        this.logger.d("Poodle has been installed!", {
          id: this.id,
          lib: LIBRARY_NAME,
          version: LIBRARY_VERSION,
          isDebug: this.isDebug,
          environment: this.environment,
          partner: this.messagePartner,
          region: this.messageRegion,
        });

        if (options.onInstalled) {
          options.onInstalled();
        }
      })
      .catch(() => {
        this.logger.w("Poodle was already installed", {
          id: this.id,
          lib: LIBRARY_NAME,
          version: LIBRARY_VERSION,
          isDebug: this.isDebug,
          environment: this.environment,
          partner: this.messagePartner,
          region: this.messageRegion,
        });
      });
  }

  /**
   * Override
   */
  public open(options?: PoodleLaunchOptions): void {
    this.logger.d("Open Poodle", {
      id: this.id,
      lib: LIBRARY_NAME,
      version: LIBRARY_VERSION,
      isDebug: this.isDebug,
      environment: this.environment,
      partner: this.messagePartner,
      region: this.messageRegion,
      options,
    });

    this.updateContainer(true, options);
    this.sendMessage(PoodleCommands.OPEN, options);
  }
}
