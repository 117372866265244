import { MessengerOptions } from "./MessengerOptions";
import { Pupper } from "../bark/Pupper";
import { DOMMessenger } from "./DOMMessenger";

/**
 * A messenger backed by an iframe on the document
 */
export class IframeMessenger extends DOMMessenger<MessengerOptions> {
  /**
   * The iframe
   */
  private iframe: HTMLIFrameElement | "nothing" = "nothing";

  /**
   * Logger
   */
  private readonly logger: Pupper;

  constructor() {
    super();
    this.logger = new Pupper("message/IframeMessenger");
  }

  /**
   * Lazy resolve the iframe the first time it is asked for
   */
  private lazyIframe(): HTMLIFrameElement {
    const id = this.id;
    if (!id) {
      const msg = "Missing iframe id!";
      this.logger.w(msg);
      throw new Error(msg);
    }

    if (this.iframe === "nothing") {
      this.logger.d("Locate iframe in DOM: ", id);
      this.iframe = document.getElementById(id) as HTMLIFrameElement;
    }

    const frame = this.iframe;
    if (!frame) {
      const msg = `Missing expected iframe: #${id}`;
      this.logger.e(msg);
      throw new Error(msg);
    } else {
      return frame as HTMLIFrameElement;
    }
  }

  /**
   * Post a message
   *
   * @param message
   * @param origin
   * @param options
   */
  postMessage(message: any, origin: string, options: MessengerOptions): void {
    this.logger.setDebug(options.debug);
    const iframe = this.lazyIframe();

    const { contentWindow } = iframe;
    if (!contentWindow) {
      const msg = `iframe has no attached content window: #${iframe.id}`;
      this.logger.e(msg);
      throw new Error(msg);
    }

    this.logger.d("Post message into iframe: ", {
      message,
      origin,
      options,
    });

    contentWindow.postMessage(message, origin);
  }
}
