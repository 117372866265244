/**
 * A simple, perhaps too Dog themed, logger
 */
import { Bark } from "./Bark";
import { LIBRARY_NAME, LIBRARY_VERSION } from "../lib";

export class Pupper implements Bark {
  private readonly tag: string;
  private isDebug: boolean;

  constructor(tag: string, debug: boolean = false) {
    this.tag = `[(${LIBRARY_NAME}-${LIBRARY_VERSION})=${tag}]:`;
    this.isDebug = debug;
  }

  /**
   * Set debug state
   * @param debug
   */
  setDebug(debug: boolean): void {
    this.isDebug = debug;
  }

  /**
   * Override
   *
   * @param args
   */
  d(...args: any[]): void {
    if (this.isDebug) {
      console.log(this.tag, ...args);
    }
  }

  /**
   * Override
   *
   * @param args
   */
  w(...args: any[]): void {
    if (this.isDebug) {
      console.warn(this.tag, ...args);
    }
  }

  /**
   * Override
   *
   * @param args
   */
  e(...args: any[]): void {
    if (this.isDebug) {
      console.error(this.tag, ...args);
    }
  }
}
